

    <div [className]="('DIR'|translate) === 'rtl' ? 'rtl': 'ltr'">
<!--      <div class="text-center overlay row-container"  >-->
<!--&lt;!&ndash;         <script *ngIf="spinner==true">alert('hhhhhhhhhhhh') </script>&ndash;&gt;-->
<!--        <div class="spinner-border spinner-border-sm text-info" style="width: 5rem; height: 5rem;margin-top: 100px"></div>-->
<!--      </div>-->

      <div class="overlayy" *ngIf="loading==true">
        <mat-progress-spinner class="spinnerr custom-spinner" [color]="color" [mode]="mode" [value]="value">
        </mat-progress-spinner>
      </div>
<!--      <div id="pause" class="d-flex   align-items-center justify-content-center " *ngIf="loading==true">-->

<!--        <div id="spinner"> -->
<!--        </div>-->

<!--      </div>-->
        <app-etaam-header ></app-etaam-header>
        <app-popup></app-popup>
        <div class="page" style="margin-bottom: 110px;">
            <router-outlet></router-outlet>
        </div>
        <div style="margin-bottom: -1px">
        <app-etaam-footer ></app-etaam-footer>
        </div>
    </div>


