<div class="donation-plan text-center">
    <p class="title1 plan">{{donationPlan.title}} </p>
    <div class="etaam-border plan-desc">
        <p *ngFor="let feature of donationPlan.features"> Feature 1</p>


        <p class="plan-price"> {{donationPlan.price}}</p>

        <button class="btn plan-btn etaam-border"> Select </button>
    </div>
</div>