<div class="about page">
    <div class="container">
        <div class="card">
            <div class="row ">
                <div class="col-md-9 col-sm-8 col-xs-12 identification text-left">
                    <h3 class="main-title text-left">{{'GENERAL_PAGES.ABOUT.ABOUT'|translate}}</h3>
                    <p class="card-body">
                        {{about.desc}}
                    </p>
                    <!-- <div class="">
                        <ul>
                            <li> <b>{{'GENERAL_PAGES.ABOUT.ORGANIZATION_NAME'|translate}}:</b> {{about.organization_name}} </li>
                            <li> <b>{{'GENERAL_PAGES.ABOUT.LOCATION'|translate}}: </b> {{about.location}} </li>
                            <li> <b>{{'GENERAL_PAGES.ABOUT.LICENCE_NUMBER'|translate}}</b> {{about.licence_num}} </li>
                            <li> <b>{{'GENERAL_PAGES.ABOUT.DATE'|translate}} </b> {{about.date}}</li>
                        </ul>
                    </div> -->
                </div>
                <div class="col-md-3 col-sm-4 col-xs-12 ">
                    <img class="img-responsive" src="assets/images/about/about-page.png">
                </div>
            </div>
        </div>

        <div class="row">
            <div class="col-md-6 col-sm-12 col-xs-12 pull-left text-left">
                <div class="card">
                    <div class="card-img" style="background-image:url('assets/images/about/vision.png')"></div>
                    <h3 class="main-title text-center">
                        <!-- <i class="fa fa-eye"></i>&nbsp;  -->
                        {{'GENERAL_PAGES.ABOUT.VISION'|translate}}
                    </h3>
                    <p class="card-body text-center">{{about.vision}}</p>
                </div>
            </div>

            <div class="col-md-6 col-sm-12 col-xs-12 pull-left text-left">
                <div class="card">
                    <div class="card-img" style="background-image:url('assets/images/about/mission.png')"></div>
                    <h3 class="main-title text-center">
                        <!-- <i class="fa fa-envelope"></i>&nbsp;  -->
                        {{'GENERAL_PAGES.ABOUT.MISSION'|translate}}
                    </h3>
                    <p class="card-body text-center">{{about.mission}}</p>
                </div>
            </div>
        </div>
        <div class="card">
            <div class="row ">
                <div class="col-md-3 col-sm-4 col-xs-12 pull-left">
                    <div class="card-img" style="background-image:url('assets/images/about/goals.png')"></div>
                </div>
                <div class="col-md-9 col-sm-8 col-xs-12 pull-left text-left">
                    <h3 class="main-title">
                        <!-- <i class="fa fa-bullseye"></i>&nbsp;  -->
                        {{'GENERAL_PAGES.ABOUT.GOALS'|translate}}
                    </h3>
                    <p>{{about.goals}}</p>
                    <ol class="text-left">
                        <li>  تلقي المعونات والتبرعات والهبات والوصايا والأوقاف.  </li>
                        <li>    عقد شراكات واتفاقيات الدعم.</li>
                        <li>    الإسهام في توفير الكفاءات الوطنية المؤهلة للعناية بالقرآن الكريم.</li>
                        <li>    دعم المبادرات الهادفة لرفع مستوى فعالية العناية بالقرآن الكريم.</li>
                        <li>    الإسهام في تنمية قدرات معلمي القرآن الكريم.</li>
                        <li>    المساعدة في تحقيق معايير الجودة الشاملة في تلاوة وتحفيظ وتجويد القرآن الكريم.</li>
                        <li>   إ جراء الدراسات والبحوث العلمية التي تخدم العناية بالقرآن الكريم.</li>
                    </ol>
                </div>
            </div>
        </div>
    </div>
</div>