<div class="card text-left">
    <img class="card-img-top" [src]="library.image" alt="Magazine">
    <div class="card-body">
        <h3 class="card-title"> {{library.name}} </h3>
        <i class="fas fa-cloud-download-alt"></i>
        <button class="btn-download inline">
           <i class="fa fa-cloud-download"></i>
           {{'LIBRARY.DOWNLOAD_MAGAZINE'|translate}}
        </button>
    </div>
</div>
