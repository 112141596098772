import { Injectable } from '@angular/core';
import { HttpHeaders,HttpRequest,HttpParams  } from '@angular/common/http';
import { BaseHttpService } from '../../home/services/base-http.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  constructor(private baseHttpService:BaseHttpService) { }

  submitloginForm(body){
    return this.baseHttpService.post('/api/login_with_email',{...body}, {});
  }

 


}
