import { Component, OnInit, Input } from '@angular/core';
import { Team } from '../../models/team';

@Component({
  selector: 'app-team-member',
  templateUrl: './team-member.component.html',
  styleUrls: ['./team-member.component.scss']
})
export class TeamMemberComponent implements OnInit {
  @Input()team:Team;
  constructor() { }

  ngOnInit(): void {
  }

}
