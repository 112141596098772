export class registerModel {

  application_id: 25;
  lang_id: 2;
  full_name: string;
  email: string;
  fax: string;
  landline: string;
  zip_code: string;
  license_number: string;
  password: string;
  confirm_password: string;
  gender_id: number;
  user_type_id: number;
  rule_id: number;
  mobile:number;
  association_district_id: number;
  association_id: number;
  association_code: number;
  association_job: string;

}
