import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

import {AuthenticationService} from '../../services/authentication.service';
import {HomeService} from '../../../home/services/home.service';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  loginForm: FormGroup;
  registrationForm: FormGroup;


  token;

  constructor(formBuilder: FormBuilder,
              private toastr: ToastrService,
              private authService: AuthenticationService,
              private router: Router, private homeService: HomeService) {

    this.loginForm = formBuilder.group({
      email: ['', Validators.compose([Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])],
      password: ['', Validators.compose([Validators.required])]
    });


  }

  ngOnInit(): void {
    this.token = localStorage.getItem('login_token');

  }

  submitForm(formValue) {
    this.authService.submitloginForm(this.loginForm.value).subscribe((res: any) => {
      console.log("login response:", res)
      if (res.status.code === 200) {
        this.toastr.success(res.status.message)
        localStorage.setItem('login_token', res.content.api_token)
        localStorage.setItem('association_id', res.content.association.id)
        localStorage.setItem('association_name', res.content.association.name);
        localStorage.setItem('association_code', res.content.association_code);
        localStorage.setItem('association_region', res.content.association.district.name);
        localStorage.setItem('userId', res.content.id);
        console.log(res.content.association.name, res.content.association_district_id, res.content.association_code)
        this.homeService.setAssociationInfo(res.content.association.id, res.content.association_district_id, res.content.association_code, res.content.association.name)

        localStorage.setItem('isActive', res.content.is_active);


        this.router.navigate(['/home']);


      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);

      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        console.log(res.status.validation_errors)
        validation_error.forEach(element => {

          if (element.field == 'email') {
            this.toastr.error(element.message);
          } else if (element.field == 'password') {
            this.toastr.error(element.message);
          }
        })


      } else {
        this.toastr.error(res.status.error_details);
      }

    }, error => {
      this.toastr.error('حدث خطأ في التسجيل');
    })
    // console.log("formValue=>",formValue)

  }

}
