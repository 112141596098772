<div class="event_details internal-page">
    <div class="container">
        <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 text-center">
                <h3 class="main-title">Our Partners</h3>
            </div>
        </div>

        <div class="row">


            <div *ngFor="let partner of partners" class="col-md-2 offset-md-1 col-sm-3 col-xs-4 pull-left">
                <div class="card">
                    <img [src]="partner.image" class="img-responsive company-logo" alt="partner.name">
                </div>
            </div>

        </div>
    </div>
</div>
