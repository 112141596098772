<div class="popup" *ngIf="opened">
    <span class="close" (click)="close($event);valueChanged()">
        <i class="fa fa-times"></i>
      </span>
      <div class="content">
        <form>
            <div class="form-group">
              <label for="exampleInputEmail1">Title</label>
              <input type="text" class="form-control" id="exampleInputEmail1" >
              <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
            </div>
            <div class="form-group">
              <label for="exampleFormControlTextarea1">Details</label>
              <textarea class="form-control" id="exampleFormControlTextarea1" rows="2"></textarea>    
            </div>
            <div class="form-group">
                <label for="exampleInputEmail2">Amount</label>
                <div class="input-group mb-2" style="position: relative;">
                    <div class="input-group-prepend" style="position: absolute;
                    right: 0;
                    z-index: 1;">
                      <div class="input-group-text">SAR</div>
                    </div>
                <input type="number" step="1" class="form-control" id="exampleInputEmail2" >
                <!-- <small id="emailHelp" class="form-text text-muted">We'll never share your email with anyone else.</small> -->
              </div>
            </div>
              
           

            
          </form>
       
          <button class="btn btn-xs btn-custom new"  >Proceed</button>


      </div> 
</div>
<div class="style" *ngIf="opened"></div>