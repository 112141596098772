import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule, SwiperConfigInterface, SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { CarouselModule } from 'ngx-owl-carousel-o';
//Custom Modules and components
import { AuthenticationModule } from '../authentication/authentication.module';

import { EtaamFooterComponent } from './components/etaam-footer/etaam-footer.component';
import { EtaamHeaderComponent } from './components/etaam-header/etaam-header.component';
import { CardEventOpportunityComponent } from './components/card-event-opportunity/card-event-opportunity.component';
import { CardOpportunityComponent } from './card-opportunity/card-opportunity.component';
import { RouterModule } from '@angular/router';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [
    EtaamFooterComponent,
    EtaamHeaderComponent,
    CardEventOpportunityComponent,
    CardOpportunityComponent,
  ],
  exports:[
    EtaamFooterComponent,
    EtaamHeaderComponent,
    CardEventOpportunityComponent,
    CardOpportunityComponent
  ],
  imports: [
    CommonModule,
    SwiperModule,
    CarouselModule,
    TranslateModule.forChild(),
    AuthenticationModule,
    RouterModule

  ],
  providers: [
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG
    }
  ],
})
export class SharedModule { }
