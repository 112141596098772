import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-auth-layout',
  templateUrl: './auth-layout.component.html',
  styleUrls: ['./auth-layout.component.scss']
})
export class AuthLayoutComponent implements OnInit {
  registrationRef: Boolean = false;

  opened = true;
  register_popup = false;
  myRig = false;


  @Output() closeLoginPopup = new EventEmitter();
  constructor() { }

  ngOnInit(): void {
    window.addEventListener('scroll', this.noScroll);
  }

  close(event) {
    this.opened = false;
    event.preventDefault();
    window.removeEventListener('scroll', this.noScroll);
    this.valueChanged();
  }
  valueChanged() {
    this.opened = false;
    this.closeLoginPopup.emit(this.opened);
  }

  noScroll() {
    window.scrollTo(0, 0);
  }

  openRegistrationForm(event) {
    this.registrationRef = event;
  }
}
