<div class="container">
    <div class="row">
        <div class="col-md-4 col-sm-4 col-xs-12">
            <div>
                <div class="card">
                    <div>
                        <img src="../../../../assets/images/logo_default.png">
                    </div>
                    <div class="card-body ">
                        <div class="row">
                            <div class="col-md-9 col-sm-8 xol-xs-8 no-padding">
                                <div class="event-desc text-left">
                                    <h4 class="card-title"> عنوان الفاعلية</h4>
                                    <p> <i class="fa fa-map-marker"></i>&nbsp; The location of the event </p>
                                    <a class="more" routerLink="Event_Details">{{'HOME.VIEW_DETAILS'|translate}} <i class="fa fa-arrow-circle-right"></i></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
