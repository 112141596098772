import { Component, OnInit, Output, EventEmitter } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-login-popup',
  templateUrl: './login-popup.component.html',
  styleUrls: ['./login-popup.component.scss']
})
export class LoginPopupComponent implements OnInit {
  @Output() registrationRef = new EventEmitter();
  loginForm:FormGroup;

  constructor(private formBuilder:FormBuilder) {
    this.loginForm = formBuilder.group({
      'username':['',Validators.compose([Validators.required])],
      'password':['',Validators.compose([Validators.required])]
    })
  }

  ngOnInit() {
  }
  register(){
    this.registrationRef.emit(true);
  }

  submitForm(formValue){
      console.log("formValue=>",formValue)
  }
}
