import {Component, OnInit} from '@angular/core';
import {FormGroup, FormBuilder, Validators, FormControl} from '@angular/forms';
import {HomeService} from "../../../home/services/home.service";
import {registerModel} from "./registerModel";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";
import {isNumeric} from "rxjs/internal-compatibility";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  registrationForm: FormGroup;
  registerObj: registerModel = new registerModel();
  Districts: any;
  selectedDistrictID: any;
  EventValue: any;
  associations: any;
  selectedcharityID: any;
  associationsBlocks: any;
  association_code: any;
  password: any = 'password';
  confirmPassword: string = 'password';
  private charityName: any;
  private districName: any;
  phoneNotValid: boolean;
  secondNumNotValid: boolean;
  invalidName: boolean=false;
  faxNotValid:boolean;
  constructor(formBuilder: FormBuilder, private registerationService: HomeService, private toastr: ToastrService, private router: Router) {
    this.registrationForm = formBuilder.group({
      area: ['', Validators.compose([Validators.required])],
      charity: ['', Validators.compose([Validators.required])],
      charity_code: ['', Validators.required],
      email: ['', Validators.compose([Validators.required,Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\\.[a-zA-Z]{2,4}$")])],
      full_name: ['', Validators.compose([Validators.required,this.arabic.bind(this)])],
      job: ['', Validators.compose([Validators.required])],
      license_number: ['', Validators.compose([Validators.required])],
      mobile: ['', Validators.compose([Validators.required,this.secondPhone.bind(this)])],
     // phone_number: ['', Validators.compose([Validators.required,this.forbidphone.bind(this)])],
      phone_number: ['', Validators.compose([Validators.required, this.forbidphone.bind(this)])],
      fax_number: ['', Validators.compose([Validators.required, this.forbidFax.bind(this)])],
      postal_code: ['', Validators.compose([Validators.required])],
      user_type: ['',],
      password: ['', Validators.compose([Validators.required,Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%?&])[A-Za-z\\d$@$!%?&]{8,}$')])],
      confirm_password: ['', Validators.compose([Validators.required,Validators.pattern('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%?&])[A-Za-z\\d$@$!%?&]{8,}$')])],
    })
  }

  ngOnInit(): void {
    this.registerationService.getDistricts().subscribe((res: any) => {
      this.Districts = res.content;
    });
  }

  getAssociationsByDistrictID(associationID) {
    debugger
    this.districName = associationID.target.options[associationID.target.options.selectedIndex].text;

    this.selectedDistrictID = associationID.target.value;
    this.EventValue = associationID.target.value;
    this.registerationService.getAssociationsByDistrictID(this.selectedDistrictID).subscribe((res: any) => {
      this.associations = res.content;
      console.log('association by distI',res)
    })
  }
  arabic(control:FormControl):{ [p: string]: boolean }{
    this.invalidName=true;
    let regex=new RegExp("^[\u0621-\u064A0-9 ]+$");
    let match =regex.test(control.value);
    for(let i=0;i<control.value.length;i++){
      var num= isNumeric(control.value[i])
    }
    if (num==false&&match==true){
      this.invalidName=false;
      return null
    }else{
      this.invalidName=true;
      return {'notArabic':true}
    }
  }
  forbidphone(controle: FormControl): { [p: string]: boolean } {
    this.phoneNotValid = false;
    let m = controle.value;
    let t = m.substring(0, 2);
    if ((t === "01" || t === "۰۱") && (m.length == 10)&&isNumeric(m)==true) {
      return null
    } else if (m !== '') {
      this.phoneNotValid = true;
      return {'invalid_number': true}
    }
    return null;
  }
  forbidFax(controle: FormControl): { [p: string]: boolean } {
    this.faxNotValid = false;
    let m = controle.value;
    let t = m.substring(0, 2);
    if ((t === "01" || t === "۰۱") && (m.length == 10)&&isNumeric(m)==true) {
      return null
    } else if (m !== '') {
      this.faxNotValid = true;
      return {'invalid_number': true}
    }
    return null;
  }
  changgetCharities(charityID) {
    this.charityName = charityID.target.options[charityID.target.options.selectedIndex].text;

    this.selectedcharityID = charityID.target.value;
    console.log("El Gam3eya Event Value", charityID.target.value)
    this.registerationService.showAssociationByID(this.selectedcharityID).subscribe((res: any) => {
      this.associationsBlocks = res.content[0];
      this.association_code = res.content[0].code;
      console.log("gam3eyat men el manatek", this.associationsBlocks)
      console.log("code el gam3eya ========>", this.association_code)

    })
  }

  register(value: any) {
    if (value.invalid && value.pristine) {
      value.markAllAsTouched()
    } else if (value.invalid && value.dirty) {
      Object.keys(value.controls).forEach(field => {
        const control = value.get(field);
        control.markAsTouched({onlySelf: true});
      });
      this.toastr.error('من فضلك ادخل باقي البيانات');
      console.log("form is still invalid")

    } else if (this.association_code != this.registrationForm.value['charity_code']) {
      this.toastr.error('كود الجمعية غير صحيح');
    } else if (value.valid == true) {
      this.SubmitRegisteration();
    }
  }

  SubmitRegisteration() {
    debugger
    this.registerObj.association_code = this.association_code;
    this.registerObj.association_id = this.selectedcharityID;
    this.registerObj.association_district_id = this.selectedDistrictID;
    this.registerObj.association_job = this.registrationForm.get('job').value;
    this.registerObj.license_number = this.registrationForm.get('license_number').value;
    this.registerObj.email = this.registrationForm.get('email').value;
    this.registerObj.fax = this.registrationForm.get('fax_number').value;
    this.registerObj.zip_code = this.registrationForm.get('postal_code').value;
    this.registerObj.full_name = this.registrationForm.get('full_name').value;
    this.registerObj.landline = this.registrationForm.get('phone_number').value;
    this.registerObj.mobile=this.registrationForm.get('mobile').value;
    this.registerObj.user_type_id = this.registrationForm.get('user_type').value;
    this.registerObj.password = this.registrationForm.get('password').value;
    this.registerObj.confirm_password = this.registrationForm.get('confirm_password').value;
    this.registerationService.register(this.registerObj).subscribe(res => {
      console.log(res);
      if (res.status.code === 200) {
        this.toastr.success(res.status.message);
        localStorage.setItem('charityName', this.charityName);
        localStorage.setItem('districName', this.districName);
       // this.registerationService.setAssociationInfo(this.registerObj.association_id, this.registerObj.association_district_id, this.association_code)
        // this.router.navigateByUrl('/Email-Verification');
        localStorage.setItem('association_id', res.content.id)
        console.log("association ID from local storage", localStorage.getItem('association_id'));
        this.router.navigate(['/login']);
      } else if (res.status.code === 401) {
        this.toastr.error(res.status.error_details);
      } else if (res.status.code === 403) {
        let validation_error = res.status.validation_errors;
        console.log(res.status.validation_errors)
        validation_error.forEach(element => {
          if (element.field == 'application_id') {
            this.toastr.error(element.message);
          } else if (element.field == 'district_id') {
            this.toastr.error(element.message);
          } else if (element.field == 'full_name') {
            this.toastr.error(element.message);
          } else if (element.field == 'association_id') {
            this.toastr.error(element.message);
          } else if (element.field == 'association_code') {
            this.toastr.error(element.message);
          } else if (element.field == 'license_number') {
            this.toastr.error(element.message);
          } else if (element.field == 'district_name') {
            this.toastr.error(element.message);
          } else if (element.field == 'street_name') {
            this.toastr.error(element.message);
          } else if (element.field == 'landline') {
            this.toastr.error(element.message);
          } else if (element.field == 'fax') {
            this.toastr.error(element.message);
          } else if (element.field == 'email') {
            this.toastr.error(element.message);
          } else if (element.field == 'mobile') {
            this.toastr.error(element.message);
          } else if (element.field == 'module_id') {
            this.toastr.error(element.message);
          } else if (element.field == 'zip_code') {
            this.toastr.error(element.message);
          } else if (element.field == 'password') {
            this.toastr.error(element.message);
          } else if (element.field == 'confirm_password') {
            this.toastr.error(element.message);
          }
        })
      } else {
        this.toastr.error(res.status.message)
      }
    })
  }
  secondPhone(controle: FormControl): { [p: string]: boolean } {
    this.secondNumNotValid = false;
    let m = controle.value;
    let t = m.substring(0, 2);

    if ((t === "05" || t === "۰٥") && (m.length == 10)&&isNumeric(m)==true) {
      return null
    } else if (m !== '') {
      this.secondNumNotValid = true;
      return {'invalid_number': true}
    }
    return null;

  }
  changPasswordVisiabilty() {
    if (this.password == 'password') {
      this.password = 'text'
    } else {
      this.password = 'password'
    }
  }

  changConfirmPasswordVisiabilty() {
    if (this.confirmPassword == 'password') {
      this.confirmPassword = 'text'
    } else {
      this.confirmPassword = 'password'
    }
  }
}
