
<div class="contact page">
    <div class="container">
        <h3 class="main-title text-left">{{'GENERAL_PAGES.CONTACT.CONTACT_US'|translate}}</h3>
        <div class="row card">
                <div class="col-md-12 col-sm-12 col-xs-12 no-padding">
                  <div class="col-md-8 col-sm-12 col-xs-12 pull-left text-left" id="left">
                    <h4 class="main-title uppercase bold">{{'GENERAL_PAGES.CONTACT.MAIN_BRANCH'|translate}}</h4>
                    <div class="branch">
                      <!-- <h6 class="uppercase bold">المركز الرئيسي</h6> -->
                      <div class="contact-info">
                        <p><i class="fa fa-envelope gold"></i> <b>الصندوق البريدي:</b> &nbsp;<span> 11232 الرياض</span></p>
                        <p><i class="fa fa-phone gold"></i> <b>تليفون :</b> &nbsp;<span>0112238051</span><span class="vertical_separator"></span><span>0112238051</span></p>
                        <p><i class="fa fa-fax gold"></i> <b>فاكس:</b> &nbsp;<span>0112236298</span></p>
                        <p><i class="fa fa-envelope gold"></i> <b>البريد الاكتروني:</b> &nbsp;<span>quranb@moia.gov.sa</span></p>
                        <p><i class="fa fa-map-marker gold"></i> <b>العنوان:</b>  &nbsp;<span>
                          الرياض - طريق الملك فهد الفرعي، العليا، وزارة الشؤون الإسلامية والدعوة والإرشاد، الدور 20.</span></p>
                      </div>
                    </div>
                    <!-- <div class="branches">
                      <h5 class="uppercase bold">{{'GENERAL_PAGES.CONTACT.OTHER_BRANCHES'|translate}}</h5>
                      <div class="scroll">
                        <div class="branch">
                          <h6 class="uppercase bold"> Reyadh Branch</h6>
                          <div class="contact-info">
                            <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                            <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                            <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                          </div>
                        </div>
                        <div class="branch">
                          <h6 class="uppercase bold"> Reyadh Branch</h6>
                          <div class="contact-info">
                            <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                            <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                            <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                          </div>
                        </div>
                        <div class="branch">
                          <h6 class="uppercase bold"> Reyadh Branch</h6>
                          <div class="contact-info">
                            <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                            <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                            <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                          </div>
                        </div>
                        <div class="branch">
                          <h6 class="uppercase bold"> Reyadh Branch</h6>
                          <div class="contact-info">
                            <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                            <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                            <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                          </div>
                        </div>
                        <div class="branch">
                          <h6 class="uppercase bold"> Reyadh Branch</h6>
                          <div class="contact-info">
                            <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                            <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                            <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                          </div>
                        </div>
                        <div class="branch">
                          <h6 class="uppercase bold"> Reyadh Branch</h6>
                          <div class="contact-info">
                            <p><i class="fa fa-phone gold"></i><span>195344345</span><span class="vertical_separator"></span><span>1953353454</span></p>
                            <p><i class="fa fa-envelope gold"></i><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span><span class="vertical_separator"></span><span>info.charitybox.com</span></p>
                            <p><i class="fa fa-map-marker gold"></i><span>12 street - Reyadh -Saudia Arabia</span></p>
                          </div>
                        </div>
                      </div>
                    </div> -->
                  </div>
                  <div class="col-md-4 col-sm-12 col-xs-12 pull-left no-padding">
                    <div class="map map-home" id="mapid" style="height: 230px;">
                      <!-- <agm-map [latitude]="latitude" [longitude]="longitude" [zoom]="zoom" >
                        <agm-marker [latitude]="latitude" [longitude]="longitude"></agm-marker>
                      </agm-map> -->
                      <div #mapContainer id="map"></div>
                    </div>
                  </div>
                </div>
                <div class="clearfix"></div>
        </div> <!--end-->
        <br>
        <div class="row card">
          <div class="col-md-12 col-sm-12 col-xs-12">
            <h3 class="main-title text-left">
              {{'GENERAL_PAGES.CONTACT.HELP'|translate}}
            </h3>
          </div>
          <div class="col-md-12 col-sm-12 col-xs-12">
            <form class="custom full-width form-padding"  >

              <div class="form-group ">
                  <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.NAME'|translate}}</label>
                  <input type="text" class="form-control">
              </div>
              <div class="form-group ">
                <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.EMAIL'|translate}}</label>
                <input type="email" class="form-control">
              </div>
              <div class="form-group ">
                <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.PHONE'|translate}}</label>
                <input type="number" class="form-control">
              </div>

              <div class="form-group more-padding">
                  <label class="pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.SUBJECT'|translate}}</label>
                  <select>
                          <option value="1">Subject1 </option>
                          <option value="2"> Subject2 </option>
                          <option value="2"> Subject3 </option>
                  </select>
              </div>

              <div class="form-group ">
                <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.MESSAGE'|translate}}</label>
                <textarea class="form-control"></textarea>
              </div>

              <div class="form-group ">
                <label class="edit_color pull-left contact-padding">{{'GENERAL_PAGES.CONTACT.ATTACHMENT'|translate}}</label>
                <input type="file" class="form-control">
              </div>

              <button type="button" class="btn-charitybox"> {{'GENERAL_PAGES.CONTACT.SEND'|translate}} </button>
              <button type="button" class="btn-charitybox-dark"> {{'GENERAL_PAGES.CONTACT.CANCEL'|translate}} </button>

          </form>
          </div>
    </div>
</div>