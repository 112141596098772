import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AboutComponent } from './pages/about/about.component';
import { ContactComponent } from './pages/contact/contact.component';
import { PartnersComponent } from './pages/partners/partners.component';
import { CeoMessageComponent } from './pages/ceo-message/ceo-message.component';
import { TeamComponent } from './pages/team/team.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TeamMemberComponent } from './components/team-member/team-member.component';
import { LibraryComponent } from './pages/library/library.component';
import { LibraryCardComponent } from './components/library-card/library-card.component';
import { NgxPaginationModule } from 'ngx-pagination';
// import { HomeComponent } from '../home/home.component';
import { SwiperModule, SWIPER_CONFIG, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { DonationCardComponent } from './components/donation-card/donation-card.component';
import { FriendlyWebsiteComponent } from './pages/friendly-website/friendly-website.component';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};
@NgModule({
  declarations: [AboutComponent,
    ContactComponent, PartnersComponent, CeoMessageComponent, TeamComponent,
    TeamMemberComponent, LibraryComponent, LibraryCardComponent, DonationCardComponent, FriendlyWebsiteComponent],
  imports: [
    CommonModule,
    TranslateModule.forChild(),
    FormsModule,
    ReactiveFormsModule,
    NgxPaginationModule,
    SwiperModule,
    CarouselModule,

  ],

})
export class GeneralPagesModule { }
