import {
  AfterViewChecked,
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  OnChanges,
  OnInit,
  ViewChild
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {EtaamHeaderComponent} from "./shared/components/etaam-header/etaam-header.component";
import {HomeService} from './home/services/home.service';
import {LoaderService} from "./services/loader.service";
declare var $:any;
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit,AfterViewChecked,AfterViewInit{
  color = 'primary';
  mode = 'indeterminate';
  value = 50;
  title = 'etaam-website';
  loading: boolean;
  constructor( private cdr: ChangeDetectorRef,private translateService: TranslateService,private homeService :HomeService,private loaderService:LoaderService) {
    this.loaderService.isLoading.subscribe((v) => {
      this.loading = v;
    });
    if(sessionStorage.getItem('etaam_lang'))  translateService.setDefaultLang(sessionStorage.getItem('etaam_lang'))
    else translateService.setDefaultLang('ar');
  }
@ViewChild(EtaamHeaderComponent,{static:true})
EtaamHeader:EtaamHeaderComponent;
  spinner: any;
  ngOnInit(): void {

  }
  ngAfterViewChecked(): void {
    let m= localStorage.getItem('login_token');
    // console.log('token from viewCheck',);
    if (m){
      this.EtaamHeader.loginFlag=false;
      this.EtaamHeader.logOutFlage=true;

    }else {
      this.EtaamHeader.logOutFlage=false;
      this.EtaamHeader.loginFlag=true;
    }
    this.cdr.detectChanges();
  }

  ngAfterViewInit(): void {
    let m= localStorage.getItem('login_token');
    if (m){
      this.EtaamHeader.loginFlag=false;
      this.EtaamHeader.logOutFlage=true;

    }else {
      this.EtaamHeader.logOutFlage=false;
      this.EtaamHeader.loginFlag=true;
    }


  }




}
