<p>team works!</p>

<div class="internal-page">
    <div class="container">
        <div class="title">
            <h1>{{'GENERAL_PAGES.TEAM.BOARD_MEMBER'|translate}}</h1>
                    <h3>{{'GENERAL_PAGES.TEAM.ITERATION_THREE'|translate}} (2020-2021)</h3>
        </div>
        <div class="row">
                <div class="m-auto">
                    <app-team-member [team]="teams[0]" class="m-auto"></app-team-member>
                </div>
        </div>
        <div class="row">
                <div class="m-auto">
                    <app-team-member [team]="teams[1]" class="m-auto"></app-team-member>
                </div>
        </div>
        <div class="row" >
            <div *ngFor="let team of teams" class="pull-left">
                <app-team-member [team]="team" class="m-auto"></app-team-member>
            </div>
        </div>
    </div>
</div>




