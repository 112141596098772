<!-- <section class="home-sponsors" *ngIf="!partnerFlag" >
    <div class="container partner-dir">
        <h3 class="main-title text-center"> {{'FOOTER.OUR_PARTNERS'|translate}} </h3>
        <div class="col-md-12 col-sm-12 col-xs-12 pull-left" style="padding-bottom: 50px !important;">
            <owl-carousel-o [options]="customOptions2">
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>
                <ng-template carouselSlide>
                    <div class="card">
                        <img src="assets/images/company.jpg" class="img-responsive company-logo" alt="partner">
                    </div>
                </ng-template>

            </owl-carousel-o>
        </div>
        <div class="clearfix"></div>
    </div>
</section>
 -->
<!-- Footer -->
<footer class="footer font-small mdb-color pt-4 color-style mt-0">

    <!-- Footer Links -->
    <div class="container text-center text-md-left">

        <!-- Footer links -->
        <div class="row text-center text-md-left  pb-3">

            <!-- Grid column -->
            <!-- <div class="col-md-2 col-lg-2 col-xs-12 mx-auto mt-3">
                <a class="footer-brand" routerLink="/"><img src="assets/images/logo.jpg"></a>

            </div> -->
            <!-- Grid column -->

            <!-- <hr class="w-100 clearfix d-md-none"> -->

            <!-- Grid column -->

            <!-- <hr class="w-100 clearfix d-md-none"> -->

            <!-- Grid column -->
            <!-- <div class="col-md-6 col-lg-6 col-xs-12 mx-auto mt-3 text-left">
                <h6 class="text-uppercase mb-4 font-weight-bold">Useful links</h6>
                <ul class="edit">
                        <li routerLink="/">{{'FOOTER.HOME'|translate}}</li>
                        <li routerLink="About">{{'FOOTER.ABOUT_ETAAM'|translate}}</li>
                        <li routerLink="Articles">الأخبار</li>
                        <li routerLink="Events">{{'FOOTER.EVENTS'|translate}}</li>

                        <li routerLink="Contact">{{'FOOTER.CONTACT_US'|translate}}</li>
                        <li> المواقع الصديقة</li>
                        <li>الشروط والأحكام</li>
                        <li>سياسة الخصوصية</li>
                        <li class="subscribe">القائمة البريدية</li>
                    </ul>
            </div> -->

            <!-- Grid column -->
            <!-- <hr class="w-100 clearfix d-md-none"> -->

            <!-- Grid column -->
            <!-- <div class="col-md-3 col-lg-3 col-xs-12 mx-auto mt-3 text-left"> -->

                <!-- <h6 class="text-uppercase mb-4 font-weight-bold">Contact</h6> -->
                <!-- <p>
                    <i class="fa fa-home mr-3"></i> Quran 5051</p>
                <p>
                    <i class="fa fa-envelope mr-3"></i> charitybox-info@gmail.com</p>
                <p>
                    <i class="fa fa-phone mr-3"></i> 0123456789
                </p> -->


                            <!-- Social buttons -->
            <!-- <div class="text-center social">
                <ul class="list-unstyled list-inline">
                    <li class="list-inline-item">
                        <a class="btn-floating btn-sm rgba-white-slight">
                            <i class="fa fa-facebook"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="btn-floating btn-sm rgba-white-slight">
                            <i class="fa fa-twitter"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="btn-floating btn-sm rgba-white-slight">
                            <i class="fa fa-youtube"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="btn-floating btn-sm rgba-white-slight">
                            <i class="fa fa-instagram"></i>
                        </a>
                    </li>
                    <li class="list-inline-item">
                        <a class="btn-floating btn-sm rgba-white-slight">
                            <i class="fa fa-snapchat"></i>
                        </a>
                    </li>
                    <li></li>
                </ul>
            </div> -->

            <!-- </div> -->
            <!-- Grid column -->



        </div>
        <!-- Footer links -->

        <hr>

        <!-- Grid row -->
        <div class="row d-flex align-items-center">

            <!-- Grid column -->
            <div class="col-md-6 col-lg-4 ml-lg-0">



            </div>
            <!-- Grid column -->

            <!-- Grid column -->
            <div class="col-md-6 col-lg-8">

                <!--Copyright-->
                <p class="text-center text-md-right">
                    {{'FOOTER.COPY_RIGHT'|translate}} <a class="footer-link" href="https://pentavalue.com/en">{{'FOOTER.PENTAVALUE'|translate}}</a> {{'FOOTER.ALL_RIGHT'|translate}}
                </p>

            </div>
            <!-- Grid column -->

        </div>
        <!-- Grid row -->

    </div>
    <!-- Footer Links -->

</footer>
<!-- Footer -->
<!-- <app-donate-popup *ngIf="myprofile" (closePopup)='closeProfile($event)'> </app-donate-popup> -->

<!-- <div class="row">
    <div class="col-md-6 col-lg-8">

        <p class="text-center text-md-right">
            {{'FOOTER.COPY_RIGHT'|translate}} <a class="footer-link" href="https://pentavalue.com/en">{{'FOOTER.PENTAVALUE'|translate}}</a> {{'FOOTER.ALL_RIGHT'|translate}}
        </p>

    </div>
</div> -->

