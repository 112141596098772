<form [formGroup]="registrationForm" (ngSubmit)="submitForm(registrationForm.value)">
  <div class="form-group">
    <label>Full name</label>
    <input type="text" class="form-control" formControlName="fullname">
    <div *ngIf="registrationForm.get('fullname').touched && registrationForm.get('fullname').invalid">
      <div *ngIf="registrationForm.get('fullname').errors.required" class=" text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Email</label>
    <input type="email" class="form-control" formControlName="email">
    <div *ngIf="registrationForm.get('email').touched && registrationForm.get('email').invalid">
      <div *ngIf="registrationForm.get('email').errors.required" class="text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
      <div *ngIf="registrationForm.get('email').errors.pattern" class="text-right validator-error">
        {{'VALIDATORS.EMAIL_PATTERN'|translate}}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Password</label>
    <input type="password" class="form-control" formControlName="password">
    <div *ngIf="registrationForm.get('password').touched && registrationForm.get('password').invalid">
      <div *ngIf="registrationForm.get('password').errors.required" class="text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Phone no.</label>
    <input type="number" class="form-control" formControlName="phone_number">
    <div *ngIf="registrationForm.get('phone_number').touched && registrationForm.get('phone_number').invalid">
      <div *ngIf="registrationForm.get('phone_number').errors.required" class="text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
    </div>
  </div>
  <div class="form-group">
    <label>Government ID</label>
    <input type="text" class="form-control" formControlName="govID">
    <div *ngIf="registrationForm.get('govID').touched && registrationForm.get('govID').invalid">
      <div *ngIf="registrationForm.get('govID').errors.required" class="text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
    </div>
  </div>
  <button class="btn btn-xs btn-custom new" [disabled]="!registrationForm.valid">Register</button>
</form>
