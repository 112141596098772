import { Component, OnInit } from '@angular/core';
import { partner } from '../../models/partner';

@Component({
  selector: 'app-partners',
  templateUrl: './partners.component.html',
  styleUrls: ['./partners.component.scss']
})
export class PartnersComponent implements OnInit {
  partners:Array<partner> = []
  constructor() { }

  ngOnInit(): void {
    for(let i = 0; i<8; i++){
      this.partners.push(
        {
          "name":`partner${i}`,
          "image":"assets/images/company.jpg",
          "id":i
        }
      )
    }
  }

}
