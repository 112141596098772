import { Component, OnInit } from '@angular/core';
import { Library } from '../../models/library';

@Component({
  selector: 'app-library',
  templateUrl: './library.component.html',
  styleUrls: ['./library.component.scss']
})
export class LibraryComponent implements OnInit {
  collectionMagazine = [];
  page: number = 1;
  libraries:Array<Library> = []

  constructor() { }

  ngOnInit(): void {
    for(let i=0; i<8;i++){
      this.libraries.push(
        {
          "name":`magazine${i}`,
          "image":"assets/images/30-800x600.jpg"
        }
      )
    }
  }

}
