<form [formGroup]="loginForm" (ngSubmit)="submitForm(loginForm.value)">
  <div class="form-group">
    <label>Username</label>
    <input type="text" class="form-control" formControlName="username">
    <div *ngIf="loginForm.get('username').touched && loginForm.get('username').invalid">
      <div *ngIf="loginForm.get('username').errors.required" class=" text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
    </div>
  </div>

  <div class="form-group">
    <label>Password</label>
    <input type="password" class="form-control" formControlName="password">
    <div *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid">
      <div *ngIf="loginForm.get('password').errors.required" class=" text-right validator-error">
        {{'VALIDATORS.REQUIRED'|translate}}
      </div>
    </div>
  </div>
  <button class="btn btn-xs btn-custom new" [disabled]="!loginForm.valid" >Login</button>
  <a class="btn btn-xs btn-custom new" [routerLink]="['registration']">Register</a>
</form>

