import { Component, OnInit } from '@angular/core';
import { Team } from '../../models/team';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  styleUrls: ['./team.component.scss']
})
export class TeamComponent implements OnInit {

  constructor() { }
  teams:Array<Team> = [
    {name:'Walid bin Amer',title:' Member of the Board of Directors',image:'../../../assets/images/p3.jpg'},
    {name:' Abdul Latif bin Abdullah',title:' Member of the Board of Directors',image:'../../../assets/images/p2.jpg'},
    {name:' AMoadi bin Masoud',title:' Member of the Board of Directors',image:'../../../assets/images/p1.jpg'},
    {name:' Tariq bin Ali Ahemd',title:' Member of the Board of Directors',image:'../../../assets/images/p4.jpg'},
    {name:' Abdul Latif bin Abdullah',title:' Vice Chairman of the Board of Directors',image:'../../../assets/images/p5.jpg'},
    {name:' Khalid bin Jamal',title:' Chairman of the Board of Directors  ',image:'../../../assets/images/p6.jpeg'}
  ]
  ngOnInit(): void {
  }

}
