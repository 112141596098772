import {Injectable} from '@angular/core';
import {HttpHeaders, HttpRequest, HttpParams} from '@angular/common/http';
import {BaseHttpService} from './base-http.service';
import {Observable} from 'rxjs';

interface associationInfo {
  id: string;
  code: string;
  region: string;
  name: string
}

@Injectable({
  providedIn: 'root'
})
export class HomeService {
  isloading:boolean=true;
  header: HttpHeaders = new HttpHeaders();
  associationInformation: associationInfo = {id: '', code: '', region: '', name: ''};
  editeObj: any;

  constructor(private baseHttpService: BaseHttpService,) {
  }

  getDistricts() {
    return this.baseHttpService.post('/api/association_districts', {
      'module_id': 494,
      'lang_id': 1
    }, {});
  }

  getAssociationsByDistrictID(districtID) {
    return this.baseHttpService.post('/api/get_associations'
      , {
        'application_id': 25,
        'lang_id': 2,
        'district_id': districtID
      }, {});
  }

  getAllAssociations() {
    return this.baseHttpService.post('/api/associations', {
      'module_id': 494,
      'lang_id': 1
    }, {});
  }

  PostCharityForm(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/associations/store', {...body}, {headers: headerNotification});
  }

  postCeoForm(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));

    return this.baseHttpService.post('/api/association_managers/store', {...body}, {headers: headerNotification});
  }

  postManagerForm(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/association_management_boards/store', {...body}, {headers: headerNotification});
  }

  associationRequests(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    // {headers: headerNotification}
    return this.baseHttpService.post('/api/association_orders/store', {...body}, {
      headers: new HttpHeaders({
        'access-token': localStorage.getItem('login_token'),
        'backend': '12',
        'Content-Type': 'application/json'
      })
    });
  }

  editAssociationRequestById(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/association_orders/update', {...body}, {headers: headerNotification});
  }

  showAssociationByID(associationID) {
    return this.baseHttpService.post('/api/associations/show', {
      'module_id': 494,
      'lang_id': 1,
      'id': associationID
    }, {});
  }

  addCommentToRequest(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/association_orders/add_comment', body, {headers: headerNotification});
  }

  updateRequest(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/association_orders/update', body, {headers: headerNotification});
  }

  GetOwnershipsTypes() {
    return this.baseHttpService.post('/api/association_ownerships', {}, {});
  }

  GetBuildings() {
    return this.baseHttpService.post('/api/association_build_status', {}, {});
  }

  GetBankList() {
    let obj = {
      'module_id': 509,
    };
    return this.baseHttpService.post('/api/grant_banks', {...obj}, {});
  }

  GetMembersList() {
    let obj = {
      'module_id': 512,
    };
    return this.baseHttpService.post('/api/association_member_positions', {...obj}, {});
  }

  register(body) {
    return this.baseHttpService.post('/api/register', body);
  }

  getComments(body) {
    let headerNotification = this.header.set('access-token', localStorage.getItem('login_token'));
    return this.baseHttpService.post('/api/association_orders/comments', body);
  }

  setAssociationInfo(id, region, code, name) {
    this.associationInformation.id = id;
    this.associationInformation.region = region;
    this.associationInformation.code = code;
    this.associationInformation.name = name;
  }

  getAssociationInfo() {
    return this.associationInformation;
  }

  edite(url, body): Observable<any> {
    let header = new HttpHeaders().set('Content-Type', 'application/json').set('access-token', localStorage.getItem('login_token'));
    this.baseHttpService.post(url, body, {headers: header}).subscribe(res => {
      this.editeObj = res.content[0];
    });
    return this.baseHttpService.post(url, body, {headers: header});
  }

}

