import { Component, OnInit } from '@angular/core';
// import { $ } from 'jquery';
import { OwlOptions } from 'ngx-owl-carousel-o';
import { Router,NavigationEnd ,NavigationStart} from '@angular/router';

@Component({
  selector: 'app-etaam-footer',
  templateUrl: './etaam-footer.component.html',
  styleUrls: ['./etaam-footer.component.scss']
})
export class EtaamFooterComponent implements OnInit {

  constructor(private router: Router) {
    this.router.events.subscribe(value => {
      this.href= router.url.toString();
      // console.log('current route: ', router.url.toString());
    //   if(this.href.includes('Partners'))
    // {
    //   this.partnerFlag=true;
    //   console.log("partnerFlag------",this.partnerFlag);

    // }
    // else
    // {
    //   this.partnerFlag=false;
    //   console.log("partnerFlag------",this.partnerFlag);

    // }
  });
}
  href="";
  // partnerFlag=false;
  // customOptions2: OwlOptions = {
  //   loop  : true,
  //   margin : 10,
  //   nav    : false,
  //   navText : ["<i class='fa fa-chevron-left'></i>","<i class='fa fa-chevron-right'></i>"],
  //   mouseDrag: false,
  //   touchDrag: false,
  //   pullDrag: false,
  //   dots: false,
  //   navSpeed: 300,
  //   autoplay: true,

  //   responsive: {
  //     0: {
  //       items: 3
  //     },
  //     400: {
  //       items: 4
  //     },
  //     740: {
  //       items: 6
  //     },
  //     940: {
  //       items: 8
  //     }
  //   },

  // }

  myprofile=false;

  ngOnInit(): void {
    // this.href=window.location.href;
    // console.log("URL >>>>>",this.href);
    // if(this.href.includes('Partners'))
    // {
    //   console.log("true");

    // }
    // else
    // {
    //   console.log("false");

    // }
    var list =document.getElementsByTagName("li");
    for (let index = 0; index < list.length; index++) {
      list[index].addEventListener("click", function() {
        window.scrollTo(0, 0)
      })

    }
  }
  viewProfile()
  {
    if(this.myprofile==false)
    {
      this.myprofile=true;
    }

    window.scrollTo(0, 0);

  }

  closeProfile(val)
  {
    // console.log("val >>>>");
    this.myprofile=val;
    // console.log("profile >>>>",this.myprofile);

  }

}
