import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AuthLayoutComponent } from './components/auth-layout/auth-layout.component';
import { RegisterPopupComponent } from './components/register-popup/register-popup.component';
import { LoginPopupComponent } from './components/login-popup/login-popup.component';
import { TranslateModule } from '@ngx-translate/core';
// import { RegistrationComponent } from './pages/registration/registration.component';
import {LoginComponent} from './pages/login/login.component'
import { RouterModule } from '@angular/router';


@NgModule({
  declarations: [AuthLayoutComponent,
    LoginPopupComponent,
    RegisterPopupComponent,
    LoginComponent
    // RegistrationComponent
  ],
  exports:[
    AuthLayoutComponent,
    LoginPopupComponent,
    RegisterPopupComponent,
    LoginComponent,
    FormsModule,
    ReactiveFormsModule
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule ,
    RouterModule,
    TranslateModule.forChild(),
  ]
})
export class AuthenticationModule { }
