import {Component, Input, OnInit} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";

@Component({
  selector: 'app-etaam-header',
  templateUrl: './etaam-header.component.html',
  styleUrls: ['./etaam-header.component.scss']
})
export class EtaamHeaderComponent implements OnInit {

loginFlag:boolean;
  myprofile=false;
  mylogin=false;

  token;
  logOutFlage: boolean;

  constructor(private translateService: TranslateService,
              private toastr: ToastrService,private router:Router) { }

  ngOnInit(): void {
    this.token = localStorage.getItem('login_token');

  }

  status: boolean = false;
  clickEvent(){
      this.status = !this.status;
  }


  closeProfile(val)
  {
    // console.log("val >>>>");
    this.myprofile=val;
    // console.log("profile >>>>",this.myprofile);

  }
  closeLogin(val)
  {
    this.mylogin=val;

  }
  viewProfile()
  {
    if(this.myprofile==false)
    {
      this.myprofile=true;
    }

  }
  viewLogin()
  {
    if(this.mylogin==false)
    {
      this.mylogin=true;
    }

  }

  changeLang(lang) {
    this.translateService.setDefaultLang(lang);
    sessionStorage.setItem("etaam_lang",lang);
  }

  logout(){
    localStorage.clear();
    this.toastr.success("لقد تم تسجيل الخروج بنجاح")
    // window.location.reload();

    // window.location.replace('/gam3eya-ahleya-front/home');
    //window.location.replace('/Gam3eya-Ahleya/home');
    localStorage.clear();
    this.router.navigate(['/login'])


  }

}
