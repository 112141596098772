import { Component, OnInit, Input } from '@angular/core';
import { Opportunity } from '../models/opportunity';

@Component({
  selector: 'app-card-opportunity',
  templateUrl: './card-opportunity.component.html',
  styleUrls: ['./card-opportunity.component.scss']
})
export class CardOpportunityComponent implements OnInit {
  @Input() opportunityDetails:Opportunity;
  @Input() fromEvent:Boolean = false;

  constructor() { }

  ngOnInit(): void {

  }

}
