<div class="page" >
    <div class="register internal-page">
      <div class="container">

        <form [formGroup]="loginForm"    (ngSubmit)="submitForm(loginForm.value)" style="margin-top:15px;padding: 60px; ">
          <fieldset  class="scheduler-border">
            <legend class="scheduler-border wizard--title"> تسجيل الدخول</legend>



            <div class="row">

              <div class="col-md-6 col-sm-6 col-xs-12 text-left">
                <div class="form-group">
                  <label class="font--bold ">البريد الالكتروني <span class="required">*</span></label>
                  <input class="form-control"
                  formControlName="email"
                          type="text">

                  <span *ngIf="loginForm.get('email').touched && loginForm.get('email').invalid">
                                      <small *ngIf="loginForm.get('email').errors.required"  class="form-text required">

                                        برجاء ادخال البريد الالكتروني
                                      </small>
                 </span>
                </div>
              </div>
              <div class="col-md-6 col-sm-6 col-xs-12 text-left">
                <div class="form-group">
                  <label class="font--bold ">كلمة السر<span class="text required">*</span>
                    </label>
                  <input  type="password"
                          class="form-control"
                          formControlName="password">

                 <span *ngIf="loginForm.get('password').touched && loginForm.get('password').invalid">
                            <small *ngIf="loginForm.get('password').errors.required"  class="form-text required">

                              برجاء ادخال كلمة السر
                            </small>
                    </span>

                </div>
              </div>

            </div>
            <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                <button  class="btn btn-success" type="submit"> تسجيل الدخول</button>

                <a  class="btn btn-success" style="margin: 0px 15px;" routerLink="/registration">التسجيل</a>

              </div>
            </div>


          </fieldset>
        </form>



      </div>

    </div>
  </div>


