
<div class="card bg-light border-secondary mb-">
    <div class="card-header">
        <h6 class="card-title pull-left">Opportunity  Title </h6>
        <button class="pull-right btn-custom"> {{'EVENTS.EVENT_DETAILS.APPLY_NOW'|translate}}</button>
    </div>
    <div class="card-body">   
        <div class="row">
            <div class="col-md-12  text-justify text-left">
                <p> <span class="data ">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, 
                    sed do eiusmod tempor incididunt ut labore et dolore magna.
                </span></p>
            </div>
        </div>     
         <div class="row">
            <div class="col-md-6 text-left">
                <p>{{'EVENTS.EVENT_DETAILS.AVAILABLE'|translate}}: <span class="data">online</span></p>
                <p>{{'EVENTS.EVENT_DETAILS.GENDER'|translate}}: <span class="data">male</span></p>
                <p>{{'EVENTS.EVENT_DETAILS.MIN_AGE'|translate}}: <span class="data">25</span></p>
               </div>
                  
               <div class="col-md-6 text-left">
                <p><i class="fa fa-calendar"></i>&nbsp;  <span class="data">25/3/2020</span></p>
                <p><i class="fa fa-hourglass-start"></i>&nbsp;  <span class="data">2:00:pm</span></p>
                <p><span class="label label-success">opened</span></p>

                
            </div>
         </div>

         <div class="row">
            <div class="col-md-12  text-justify">
               <p><i class="fa fa-map-marker"></i>&nbsp; <span class="data">El Riyad</span></p>
            </div>
        </div>    
    </div>
</div>
