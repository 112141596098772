import {Injectable} from '@angular/core';
import {HttpInterceptor, HttpRequest, HttpHandler, HttpEvent, HttpResponse} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';
import {finalize} from 'rxjs/operators';
import {HomeService} from './home.service';
import {LoaderService} from "../../services/loader.service";

const APP_ID = 25;
const AUTH_URLS = ['api/notifications', 'api/grants/store', 'api/grants_documents/store'];

@Injectable({
  providedIn: 'root'
})
export class HttpInterceptorService implements HttpInterceptor{
  private requests: HttpRequest<any>[] = [];

  constructor(private  homeService: HomeService, private loaderService: LoaderService) {
  }

  removeRequest(req: HttpRequest<any>) {

    const i = this.requests.indexOf(req);
    if (i >= 0) {
      this.requests.splice(i, 1);
    }
    this.loaderService.isLoading.next(this.requests.length > 0);
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    this.loaderService.isLoading.next(true);
    let duplicate = req.clone({body: {...req.body, 'application_id': APP_ID, lang_id: 2}});
    if(duplicate){
      this.requests.push(duplicate);
    }
    // duplicate = req.clone({body: {...req.body, 'application_id': APP_ID, lang_id: 2}});
    // let headers = duplicate.headers;
    // AUTH_URLS.forEach(URL => {
    //   if (req.url.indexOf(URL) > -1) {
    //     headers = headers.set('access-token', localStorage.getItem('login_token'));
    //   }
    //  });

    //duplicate = duplicate.clone({headers});
    // return next.handle(duplicate).pipe(finalize(() => this.onEnd()));
    return Observable.create(observer => {

      //debugger
      const subscription = next.handle(duplicate)
        .subscribe(
          event => {
            if (event instanceof HttpResponse) {
              this.removeRequest(duplicate);
              observer.next(event);
            }
          },
          err => {
            this.removeRequest(duplicate);
            observer.error(err);
          },
          () => {

            this.removeRequest(duplicate);
            observer.complete();
          });
      // remove request from queue when cancelled
      return () => {
        this.removeRequest(duplicate);
        subscription.unsubscribe();
      };
    });
  }

  onEnd(): void {

    let subject: Subject<any>;
    // this.homeService.isloading = false;
    // return subject.next({loadeSpinner: false});
  }

  isloading() {

    //  this.homeService.isloading = true;
  }
}
