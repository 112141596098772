<div class="page">
  <div class="register internal-page">
    <div class="container">
      <form [formGroup]="registrationForm" (ngSubmit)="register(registrationForm)">
        <fieldset _ngcontent-dyb-c300="" class="scheduler-border">
          <legend _ngcontent-dyb-c300=""
                  class="scheduler-border wizard--title"> {{'AUTH.REGISTER.REGISTRATION'|translate}} </legend>

          <div class="row">
            <!--E-mail-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.EMAIL'|translate}}<span class="required">*</span></label>
                <input type="email"
                       class="form-control"
                       placeholder="{{'AUTH.REGISTER.EMAIL'|translate}}"
                       formControlName="email">
                <!--Validation-->
                <span *ngIf="registrationForm.get('email').touched && registrationForm.get('email').invalid">
                                    <small *ngIf="registrationForm.get('email').errors.required"
                                           class="form-text required">
<!--                                        {{'VALIDATORS.REQUIRED'|translate}}-->
                                      {{' برجاء ادخال بريد الكتروني صحيح'}}
                                    </small>
                                    <small *ngIf="registrationForm.get('email').errors.pattern"
                                           class="form-text required">
                                        برجاء ادخال بريد الكتروني صحيح
                                    </small>
                                </span>
              </div>

            </div>
            <!--Full Name-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.FULL_NAME'|translate}}<span class="required">*</span></label>
                <input type="text"
                       class="form-control"
                       placeholder="أحرف عربي "
                       formControlName="full_name">
                <!--Validation-->
                <span *ngIf="registrationForm.get('full_name').touched && registrationForm.get('full_name').invalid">
                                    <small *ngIf="invalidName==false&& registrationForm.get('full_name').errors.required"
                                           class="form-text required">
                                        برجاء ادخال الاسم بالكامل
                                    </small>
<small *ngIf="invalidName&&registrationForm.get('full_name').dirty"
       class="form-text required">
                  برجاء ادخال الاسم صحيح
                </small>
                              </span>

              </div>
            </div>
          </div>
          <div class="row">
            <!--E-mail-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">الرقم السري <span class="required">*</span></label>
                <input id="password-fielde" [type]="password"
                       class="form-control" name="password" value="secret"
                       formControlName="password">
                <span toggle="#password-fielde" class="fa fa-fw fa-eye field-icon toggle-password"
                      (click)="changPasswordVisiabilty()"></span>
                <!--Validation-->
                <span *ngIf="registrationForm.get('password').touched && registrationForm.get('password').invalid">
                                    <small *ngIf="registrationForm.get('password').errors.required"
                                           class="form-text required">
<!--                                        {{'VALIDATORS.REQUIRED'|translate}}-->
                                      {{' برجاء ادخال الرقم السري'}}
                                    </small>
                   <small *ngIf="registrationForm.get('password').errors.pattern"
                          class="form-text required">
<!--                                        {{'VALIDATORS.REQUIRED'|translate}}-->
                     {{' برجاء ادخال رقم سري صحيح يشمل احرف كبيرة وصغيرة وارقام وعدده اكبر من 8 احرف'}}
                                    </small>
                                </span>
              </div>

            </div>

            <!--Full Name-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">تأكيد الرقم السري<span class="required">*</span></label>

                <input id="password-field" [type]="confirmPassword" class="form-control" name="password" value="secret"
                       formControlName="confirm_password">
                <span toggle="#password-field" class="fa fa-fw fa-eye field-icon toggle-password"
                      (click)="changConfirmPasswordVisiabilty()"></span>
                <!--Validation-->
                <span
                  *ngIf="registrationForm.get('confirm_password').touched && registrationForm.get('confirm_password').invalid">
                                    <small *ngIf="registrationForm.get('confirm_password').errors.required"
                                           class="form-text required">
                                        برجاء ادخال تأكيد الرقم السري
                                    </small>

                   <small *ngIf="registrationForm.get('confirm_password').errors.pattern"
                          class="form-text required">
<!--                                        {{'VALIDATORS.REQUIRED'|translate}}-->
                     {{' برجاء ادخال رقم سري صحيح يشمل احرف كبيرة وصغيرة وارقام وعدده اكبر من 8 احرف'}}
                                    </small>
                                </span>
              </div>
            </div>
          </div>
          <div class="row">
            <!--Mobile-->
            <div class="col-md-4 col-sm-4 col-xs-4 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.MOBILE'|translate}}<span class="required">*</span></label>
                <input type="text"
                       class="form-control"
                       placeholder="05xxxxxxxx"
                       formControlName="mobile" >
                <!--Validation-->
                <span *ngIf="registrationForm.get('mobile').touched && registrationForm.get('mobile').invalid">
                                    <small *ngIf="registrationForm.get('mobile').errors.required"
                                           class="form-text required">
                                        برجاء ادخال رقم الجوال
                                    </small>
                <small *ngIf="secondNumNotValid&&registrationForm.get('mobile').dirty"
                       class="form-text required">
                  الرجاء ادخال رقم جوال صحيح
                </small>
                                </span>


              </div>
            </div>
            <!--Phone Number-->
            <div class="col-md-4 col-sm-4 col-xs-4 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.PHONE_NUMBER'|translate}}<span class="required">*</span></label>

                <input type="text"
                       class="form-control"
                       placeholder="01xxxxxxxx"
                       formControlName="phone_number">
                <!--Validation-->
                <span
                  *ngIf="registrationForm.get('phone_number').touched && registrationForm.get('phone_number').invalid">
                                    <small *ngIf="registrationForm.get('phone_number').errors.required"
                                           class="form-text required">
                                        الرجاء ادخال رقم الهاتف
                                    </small>

                  <small *ngIf="phoneNotValid&&registrationForm.get('phone_number').dirty"
                         class="form-text required">
                        برجاء ادخال رقم صحيح
                                    </small>
                                </span>

              </div>
            </div>

            <!--Fax Number-->
            <div class="col-md-4 col-sm-4 col-xs-4 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.FAX_NUMBER'|translate}}<span class="required">*</span></label>
                <input type="text"
                       class="form-control"
                       placeholder="01xxxxxxxx"
                       formControlName="fax_number">

<!--                <span *ngIf="registrationForm.get('fax_number').touched && registrationForm.get('fax_number').invalid">-->
<!--                                    <small *ngIf="registrationForm.get('fax_number').errors.required"-->
<!--                                           class="form-text required">-->
<!--                                        برجاء ادخال رقم الفاكس-->
<!--                                    </small>-->
<!--                                </span>-->
<!--                <small *ngIf="secondNumNotValid&&registrationForm.get('fax_number').dirty"-->
<!--                       class="form-text required">-->
<!--                  الرجاء ادخال رقم فاكس صحيح-->
<!--                </small>-->

                <span *ngIf="registrationForm.get('fax_number').touched && registrationForm.get('fax_number').invalid">
                                    <small *ngIf="registrationForm.get('fax_number').errors.required"
                                           class="form-text required">
                                        الرجاء ادخال رقم الفاكس
                                    </small>
                                    <small *ngIf="faxNotValid && registrationForm.get('fax_number').dirty"
                                           class="form-text required">
<!--
    رقم الفاكس {{registrationForm.get('fax_number').errors.pattern.actualValue.length}}   ارقام برجاء ادخال عدد صحيح-->
                                      برجاء ادخال رقم الفاكس صحيح
                                    </small>
                                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <!--Postal Code-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.POSTAL_CODE'|translate}}<span class="required">*</span></label>
                <input type="number"
                       class="form-control"
                       placeholder="{{'AUTH.REGISTER.POSTAL_CODE'|translate}}"
                       formControlName="postal_code">
                <span
                  *ngIf="registrationForm.get('postal_code').touched && registrationForm.get('postal_code').invalid">
                                    <small *ngIf="registrationForm.get('postal_code').errors.required"
                                           class="form-text required">
                                        برجاء ادخال الرقم البريدي
                                    </small>
                                </span>
              </div>
            </div>
            <!--License Number-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.LICENSE_NUM'|translate}}<span
                  class="required">*</span></label>
                <input type="number"
                       class="form-control"
                       placeholder="{{'AUTH.REGISTER.LICENSE_NUM'|translate}}"
                       formControlName="license_number">
                <!--Validation-->
                <span
                  *ngIf="registrationForm.get('license_number').touched && registrationForm.get('license_number').invalid">
                                    <small *ngIf="registrationForm.get('license_number').errors.required"
                                           class="form-text required">
                                       برجاء ادخال رقم الرخصة
                                    </small>
                                </span>
              </div>
            </div>
          </div>

          <!-- <div class="row">
              <div class="col-md-12 col-sm-12 col-xs-12 text-left">
                  <div class="form-group">
                      <label class="font--bold">{{'AUTH.REGISTER.MAIL_BOX'|translate}}</label>
                      <input  type="number"
                              class="form-control"
                              placeholder="{{'AUTH.REGISTER.MAIL_BOX'|translate}}"
                              formControlName="mail_box">
                  </div>
              </div>
          </div> -->

          <!--          <div class="row">-->
          <!--            <div class="col-md-12 col-sm-12 col-xs-12 text-left">-->
          <!--              <div class="form-group">-->
          <!--                <label class="font&#45;&#45;bold">نوع المستخدم</label>-->
          <!--                <select class="form-control"-->
          <!--                        formControlName="user_type">-->

          <!--                  <option value="1">جمعية</option>-->
          <!--                  <option value="2"> توظيف</option>-->
          <!--                  <option value="3"> مستثمر</option>-->
          <!--                </select>-->
          <!--                <span *ngIf="registrationForm.get('user_type').touched && registrationForm.get('user_type').invalid">-->
          <!--                                        <small *ngIf="registrationForm.get('user_type').errors.required"-->
          <!--                                               class="form-text required">-->
          <!--                                        برجاء اختيار نوع المستخدم-->
          <!--                                    </small>-->
          <!--                </span>-->
          <!--              </div>-->
          <!--            </div>-->
          <!--          </div>-->

          <!-- The following 4 inputs should be shown only for the Gam3ya user type -->
          <div class="row">
            <div class="col-md-6 col-sm-6 col-xs-6 text-left">
              <div class="form-group">
                <label class="font--bold ">{{'AUTH.REGISTER.AREA'|translate}}<span class="required">*</span></label>

                <select class="form-control" formControlName="area" (change)="getAssociationsByDistrictID($event)">

                  <option *ngFor="let district of Districts" [value]="district.id">{{district.name}}</option>

                </select>
                <!--Validation-->
                <span *ngIf="registrationForm.get('area').touched && registrationForm.get('area').invalid">
                                        <small *ngIf="registrationForm.get('area').errors.required"
                                               class="form-text required">
                                        برجاء اختيار المنطقة
                                    </small>
                                </span>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 text-left">
              <div class="form-group">
                <label class="font--bold ">{{'AUTH.REGISTER.CHARITY'|translate}}<span class="required">*</span></label>
                <select class="form-control" formControlName="charity" (change)="changgetCharities($event)">
                  <option *ngFor="let association of associations"
                          [value]="association.id">{{association.name}}</option>

                </select>
                <!--Validation-->
                <span *ngIf="registrationForm.get('charity').touched && registrationForm.get('charity').invalid">
                                    <small *ngIf="registrationForm.get('charity').errors.required"
                                           class="form-text required">
                                       برجاء اختيار اسم الجمعية
                                    </small>
                                </span>
              </div>
            </div>
            <div class="col-md-6 col-sm-6 col-xs-6 text-left">
              <div class="form-group">
                <label class="font--bold ">{{'AUTH.REGISTER.CHARITY_CODE'|translate}}<span class="required">*</span></label>
                <input type="number"
                       class="form-control"
                       placeholder="{{'AUTH.REGISTER.CHARITY_CODE'|translate}}"
                       formControlName="charity_code">
              </div>
            </div>
            <!--Job-->
            <div class="col-md-6 col-sm-6 col-xs-12 text-left">
              <div class="form-group">
                <label class="font--bold">{{'AUTH.REGISTER.JOB'|translate}}<span class="required">*</span></label>
                <input type="text"
                       class="form-control"
                       placeholder="{{'AUTH.REGISTER.JOB'|translate}}"
                       formControlName="job">
                <!--Validation-->
                <span *ngIf="registrationForm.get('job').touched && registrationForm.get('job').invalid">
                                    <small *ngIf="registrationForm.get('job').errors.required"
                                           class="form-text required">
                                        برجاء ادخال الوظيفة في الجمعية
                                    </small>
                                </span>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12 col-sm-12 col-xs-12 text-left">
              <button class="btn-custom" type="submit">{{'AUTH.REGISTER.SEND'|translate}}</button>
            </div>
          </div>
        </fieldset>
      </form>


    </div>

  </div>
</div>
