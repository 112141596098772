import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import {Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class BaseHttpService {

  constructor(private httpClient: HttpClient) { }
  post(url, body, options = {}):Observable<any> {
    return this.httpClient.post(environment.BASE_URL + url, body, options);
  }
}
