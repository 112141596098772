import { Component, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-register-popup',
  templateUrl: './register-popup.component.html',
  styleUrls: ['./register-popup.component.scss']
})
export class RegisterPopupComponent implements OnInit {
  registrationForm:FormGroup;
  constructor(private formBuilder: FormBuilder) {
    this.registrationForm = formBuilder.group({
      'fullname':['',Validators.compose([Validators.required])],
      "email":['',Validators.compose([Validators.required,Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$")])],
      "password":['',Validators.compose([Validators.required])],
      "phone_number":['',Validators.compose([Validators.required])],
      "govID":['',Validators.compose([Validators.required])]
    })
  }

  ngOnInit(): void {

  }
  submitForm(formValue){
    console.log("registrationFormValue=> ", formValue)
  }


}
