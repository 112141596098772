import { Component, OnInit } from '@angular/core';
import { About } from '../../models/about';

@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss']
})
export class AboutComponent implements OnInit {
  about:About = {
    "date":"12-12-1212",
    "desc":"هو صندوق خيري يعنى بتلقي ما يبذل من دعم لمبادرات وبرامج وأنشطة العناية بالقرآن الكريم التي تُشرف عليها وزارة الشؤون الإسلامية والدعوة والإرشاد في المملكة العربية السعودية.",
    "goals":"يهدف الصندوق إلى إيجاد موارد مالية دائمة للمساهمة في الإنفاق على مبادرات وبرامج وأنشطة العناية بالقرآن الكريم، ويحقق ذلك من خلال الآتي:",
    "licence_num":121212,
    "location":"location location",
    "mission":"تحقيق الخيرية بما ينفق من تبرعات ومعونات وهبات ووصايا وأوقاف للعناية بالقرآن الكريم باستخدام أساليب إبداعية.",
    "organization_name":"Charity Box",
    "vision":"الريادة والتميز في العناية بالقرآن الكريم. "
  }
  constructor() { }

  ngOnInit(): void {
  }

}
