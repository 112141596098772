<header class="shadow">
    <div class="container">
        <nav class="navbar navbar-expand-lg navbar-light">
            <a class="navbar-brand" routerLink="/">
                <img class="logo" src="assets/logo2.jpg">
            </a>
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNavDropdown"
                aria-controls="navbarNavDropdown" aria-expanded="false" aria-label="Toggle navigation">
                <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse pull-right text-right" id="navbarNavDropdown">
                <ul class="navbar-nav">
                    <li class="nav-item">
                        <a class="nav-link" routerLink="/home">{{'HEADER.HOME'|translate}} </a>
                    </li>

                    <li class="nav-item">
                        <a *ngIf="loginFlag" class="nav-link" routerLink="/login">تسجيل الدخول</a>
                    </li>

                    <li class="nav-item">
<!--                      -->
                        <a *ngIf="logOutFlage" class="nav-link" (click)="logout()">تسجيل الخروج</a>
                    </li>

                    <!-- <li class="nav-item">
                        <a class="nav-link"  routerLink="about">
                            {{'HEADER.ABOUT'|translate}}
                        </a>
                    </li> -->

                    <!-- <li class="nav-item dropdown pull-right">
                        <a class="nav-link dropdown-toggle" href="#" id="media" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            {{'HEADER.MEDIA_CENTER'|translate}}
                        </a>
                        <div class="dropdown-menu" aria-labelledby="media">
                            <li><a class="nav-link dropdown-item" routerLink="articles">الأخبار</a></li>
                            <li><a class="nav-link dropdown-item" routerLink="event">{{'HEADER.EVENTS'|translate}}</a></li>
                        </div>
                    </li> -->
                    <!-- <li class="nav-item">
                        <a class="nav-link" [routerLink]="['contact']">{{'FOOTER.CONTACT_US'|translate}}</a>
                    </li> -->

                    <!-- <li class="nav-item">
                        <a class="nav-link" (click)="viewLogin()" style="cursor: pointer;">
                             {{'HEADER.LOGIN'|translate}}
                        </a>
                    </li> -->

                    <!-- <li class="nav-item">
                        <a class="nav-link btn-custom" style="cursor: pointer;" [routerLink]="['user-area']">{{'HEADER.beneficiary-services'|translate}}</a>
                    </li> -->

                    <!-- <li class="nav-item dropdown pull-right">
                        <a class="nav-link notification-button" href="#" id="notification" role="button"
                            data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <i class="fa fa-bell"></i>
                            <span class="num">2</span>
                        </a>
                        <div class="dropdown-menu notification-list" aria-labelledby="notification">
                            <li>
                                <a class="nav-link dropdown-item" routerLink="">
                                    <span class="float-right">
                                        نص الاشعار
                                    </span>

                                    <small class="float-right time">
                                        منذ 4 ساعات
                                    </small>
                                </a>
                            </li>

                            <li>
                                <a class="nav-link dropdown-item" routerLink="">
                                    <span class="float-right">
                                        نص الاشعار
                                    </span>

                                    <small class="float-right time">
                                        منذ 3 ساعات
                                    </small>
                                </a>
                            </li>
                        </div>
                    </li> -->

                    <!-- <li class="searchbar">
                        <span class="search-button">
                            <div class="nav-link bold search-toggle bold"  aria-hidden="true" (click)="clickEvent()">
                                <i class="fa fa-search"></i>
                            </div>
                        </span>
                         <div class="togglesearch" [ngClass]="status ? 'show' : 'hide'">
                            <input type="text" placeholder=""/>
                            <input type="button" value="بحث"/>
                        </div>
                    </li> -->

                </ul>
            </div>
        </nav>
    </div>
</header>

<app-auth-layout *ngIf="mylogin" (closeLoginPopup)='closeLogin($event)'></app-auth-layout>
<!-- <app-donate-popup *ngIf="myprofile"> </app-donate-popup> -->
