import { Component, OnInit } from '@angular/core';
import { Website } from '../../models/website';

@Component({
  selector: 'app-friendly-website',
  templateUrl: './friendly-website.component.html',
  styleUrls: ['./friendly-website.component.scss']
})
export class FriendlyWebsiteComponent implements OnInit {
  websites:Array<Website> = [
    {"name":'websiteName',
      "logo":"./assets/images/logo_default.png",
      "url":"http://www.google.com"
    }
  ]
  constructor() { }

  ngOnInit(): void {
  }

}
