import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AboutComponent } from './general-pages/pages/about/about.component';
import { ContactComponent } from './general-pages/pages/contact/contact.component';
import { PartnersComponent } from './general-pages/pages/partners/partners.component';
import { CeoMessageComponent } from './general-pages/pages/ceo-message/ceo-message.component';
import { TeamComponent } from './general-pages/pages/team/team.component';
import { LibraryComponent } from './general-pages/pages/library/library.component';
import { HomeComponent } from './home/home.component';
import { RegistrationComponent } from './authentication/pages/registration/registration.component';
import { FriendlyWebsiteComponent } from './general-pages/pages/friendly-website/friendly-website.component';
import { CharityRegistrationComponent } from './home/charity-registration/charity-registration.component';

import {LoginComponent} from './authentication/pages/login/login.component'

const routes: Routes = [
  // { path: '', component: HomeComponent },
  {path:'home',loadChildren:()=>import('./home/home.module').then(m=>m.HomeModule) },
  {path:'registration',component:RegistrationComponent},
  {path:'login',component:LoginComponent},
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'Team', component: TeamComponent },
  { path: 'about', component: AboutComponent },
  { path: 'contact', component: ContactComponent },
  { path: 'partners', component: PartnersComponent },
  { path: 'ceo_msg', component: CeoMessageComponent },
  { path: 'team', component: TeamComponent },
  { path: 'library', component: LibraryComponent },
  {path:'friendly-websites',component:FriendlyWebsiteComponent},


  // {path:'charity-registeration',component:CharityRegistrationComponent},


  {
    path: 'event',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule)
  },

  {
    path: 'articles',
    loadChildren: () => import('./articles/articles.module').then(m => m.ArticlesModule)
  },

  {
    path: 'sponsor',
    loadChildren: () => import('./sponsor/sponsor.module').then(m => m.SponsorModule)
  },


  {
    path: 'user-area',
    loadChildren: () => import('./user-area/user-area.module').then(m => m.UserAreaModule)
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes,{useHash:true})],
  exports: [RouterModule]
})
export class AppRoutingModule {

}
